import {
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpGridWrapper
} from '@rategravity/own-up-component-library';
import React from 'react';

export const TrustpilotReviews = () => (
  <OwnUpGridWrapper>
    <OwnUpGridContainer variant="slim">
      <OwnUpGridItem xs={12}>
        <div
          className="trustpilot-widget"
          data-locale="en-US"
          data-template-id="539adbd6dec7e10e686debee"
          data-businessunit-id="5e95b90631db9d0001f39ae9"
          data-style-height="500px"
          data-style-width="100%"
          data-theme="light"
          data-stars="4,5"
          data-review-languages="en"
        >
          <a
            href="https://www.trustpilot.com/review/ownup.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Trustpilot
          </a>
        </div>
      </OwnUpGridItem>
    </OwnUpGridContainer>
  </OwnUpGridWrapper>
);
