import { ownUpLightTheme } from '@rategravity/own-up-component-library';
import React from 'react';

import { Ankle } from '../components/ankle';
import { Layout } from '../components/layout';
import { ReviewsHero } from '../components/reviews/hero-review';
import { TruspilotSection } from '../components/reviews/trustpilot';
import { YotpoSection } from '../components/reviews/yotpo';
import { ReviewsMultiple } from '../components/reviews-multiple';

export const Reviews = () => {
  return (
    <Layout>
      <ReviewsHero />
      <ReviewsMultiple theme={ownUpLightTheme} />
      <TruspilotSection />
      <YotpoSection />
      <Ankle />
    </Layout>
  );
};

export default Reviews;

export function Head() {
  return (
    <script
      key="trustpilot-widget"
      type="text/javascript"
      src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
      async={true}
    />
  );
}
