import { OwnUpThemeProvider, ownUpWhiteTheme } from '@rategravity/own-up-component-library';
import React, { useEffect } from 'react';

import { OverlineWrapper } from '../../overline-text';
import { TrustpilotImotional } from '../trustpilot-imotional';
import { TrustpilotReviews } from '../trustpilot-reviews';
import { Section, SectionHeader } from './elements';

export const TruspilotSection = () => {
  useEffect(() => {
    const initTrustpilot = () => {
      if (typeof window !== 'undefined' && window.Trustpilot) {
        const widgets = window.document.getElementsByClassName('trustpilot-widget');
        Array.from(widgets).forEach((widgetElement) =>
          window.Trustpilot.loadFromElement(widgetElement)
        );
      }
    };

    if (typeof window !== 'undefined' && window.Trustpilot) {
      initTrustpilot();
    } else {
      const handleScriptLoad = () => {
        if (window.Trustpilot) {
          initTrustpilot();
        }
      };

      window.addEventListener('load', handleScriptLoad);

      return () => {
        window.removeEventListener('load', handleScriptLoad);
      };
    }
  }, []);
  return (
    <OwnUpThemeProvider theme={ownUpWhiteTheme}>
      <Section aria-label="trustpilot-reviews">
        <OverlineWrapper>
          <SectionHeader>Community reviews</SectionHeader>
        </OverlineWrapper>
        <TrustpilotImotional />
        <TrustpilotReviews />
      </Section>
    </OwnUpThemeProvider>
  );
};
